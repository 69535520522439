exports.components = {
  "component---src-pages-comment-js": () => import("./../../../src/pages/comment.js" /* webpackChunkName: "component---src-pages-comment-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-list-js": () => import("./../../../src/pages/list.js" /* webpackChunkName: "component---src-pages-list-js" */),
  "component---src-pages-placeholder-js": () => import("./../../../src/pages/Placeholder.js" /* webpackChunkName: "component---src-pages-placeholder-js" */),
  "component---src-pages-single-biographies-burak-bektas-js": () => import("./../../../src/pages/singleBiographies/BurakBektas.js" /* webpackChunkName: "component---src-pages-single-biographies-burak-bektas-js" */),
  "component---src-pages-single-biographies-oury-jalloh-js": () => import("./../../../src/pages/singleBiographies/OuryJalloh.js" /* webpackChunkName: "component---src-pages-single-biographies-oury-jalloh-js" */),
  "component---src-templates-about-page-js": () => import("./../../../src/templates/about-page.js" /* webpackChunkName: "component---src-templates-about-page-js" */),
  "component---src-templates-biography-js": () => import("./../../../src/templates/biography.js" /* webpackChunkName: "component---src-templates-biography-js" */),
  "component---src-templates-filtered-list-js": () => import("./../../../src/templates/filteredList.js" /* webpackChunkName: "component---src-templates-filtered-list-js" */)
}

